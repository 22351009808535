// material-ui
import { useTheme } from '@mui/material/styles';
import { Toolbar, useScrollTrigger } from '@mui/material';
import React from 'react';

// project import
import HeaderStyled from './HeaderStyled';
import HeaderContent from './HeaderContent';

const ElevationScroll = ({ children, window }) => {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 1 : 0,
  });
};

const Header = (props) => {
  const theme = useTheme();

  // common header
  const mainHeader = (
    <Toolbar>
      <HeaderContent />
    </Toolbar>
  );

  // app-bar params
  const appBar = {
    position: 'fixed',
    elevation: 0,
    sx: {
      background: theme.palette.primary[100],
      borderBottom: `1px solid ${theme.palette.primary.main}`,
    }
  };

  return (
    <>
      <ElevationScroll {...props}>
        <HeaderStyled open={false} {...appBar}>
          {mainHeader}
        </HeaderStyled>
      </ElevationScroll>
    </>
  );
};

export default Header;
