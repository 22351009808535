const Theme = (colors) => {
  const { grey, geekblue, red, gold, cyan, green, blue} = colors;
  const primaryColor =  geekblue;
  const secondaryColor =  grey;
  
  const greyColors = {
    0: secondaryColor[0],
    50: secondaryColor[1],
    100: secondaryColor[2],
    200: secondaryColor[3],
    300: secondaryColor[4],
    400: secondaryColor[5],
    500: secondaryColor[6],
    600: secondaryColor[7],
    700: secondaryColor[8],
    800: secondaryColor[9],
    900: secondaryColor[10],
    A50: secondaryColor[15],
    A100: secondaryColor[11],
    A200: secondaryColor[12],
    A400: secondaryColor[13],
    A700: secondaryColor[14],
    A800: secondaryColor[16]
  };
  const contrastText = '#fff';

  return {
    primary: {
      lighter: primaryColor[0],
      100: primaryColor[1],
      200: primaryColor[2],
      light: primaryColor[3],
      400: primaryColor[4],
      main: primaryColor[5],
      dark: primaryColor[6],
      700: primaryColor[7],
      darker: primaryColor[8],
      900: primaryColor[9],
      contrastText: primaryColor[0]
    },
    secondary: {
      lighter: greyColors[100],
      100: greyColors[100],
      200: greyColors[200],
      light: greyColors[300],
      400: greyColors[400],
      main: greyColors[500],
      600: greyColors[600],
      dark: greyColors[700],
      800: greyColors[800],
      darker: greyColors[900],
      A100: greyColors[0],
      A200: greyColors.A400,
      A300: greyColors.A700,
      contrastText: greyColors[0]
    },
    error: {
      lighter: red[0],
      light: red[2],
      main: red[4],
      dark: red[7],
      darker: red[9],
      contrastText
    },
    warning: {
      lighter: gold[0],
      light: gold[3],
      main: gold[5],
      dark: gold[7],
      darker: gold[9],
      contrastText: greyColors[100]
    },
    info: {
      lighter: cyan[0],
      light: cyan[3],
      main: cyan[5],
      dark: cyan[7],
      darker: cyan[9],
      contrastText
    },
    success: {
      lighter: green[0],
      light: green[3],
      main: green[5],
      dark: green[7],
      darker: green[9],
      contrastText
    },
    grey: greyColors
  };
};

export default Theme;