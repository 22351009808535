import { UPDATE_INVENTORY_PAGINATION, INVENTORY_PAGINATION_RESET } from '../../../consts/reduxActions';

const initialTableFiltersState = {
  pagination: {
    current: 1,
    pageSize: 10,
    pageSizeOptions: ['10', '20', '30', '50'],
    showSizeChanger: true,
    position: ['bottomRight'],
    sorter: {},
    total: 10
  },
  sorter: {},
  vendor: [],
  categoryname: [],
  search: ''
};

export const tableFiltersReducer = (state = initialTableFiltersState, action) => {
  switch (action.type) {
    case UPDATE_INVENTORY_PAGINATION:
      return {
        ...state,
        ...action.payload
      };
    case INVENTORY_PAGINATION_RESET:
      return {
        ...initialTableFiltersState
      };
    default:
      return state;
  }
};
