import { Button, Box } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom'; // import useNavigate
import HeaderNavigationStyled from './HeaderNavigationStyled';

const NavigationButton = ({ label, routePath, icon }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const isSelected = location.pathname === routePath;

    const handleNavigation = () => {
        navigate(routePath);
    };

    return (
        <HeaderNavigationStyled isSelected={isSelected}>
            <Box className="parentBox">
                {icon}
                <Button 
                    variant="contained" 
                    className="styledButton"
                    onClick={handleNavigation}
                >
                    {label}
                </Button>
            </Box>
        </HeaderNavigationStyled>
    );
};

export default NavigationButton;
