import { Outlet } from 'react-router-dom';

// material-ui
import { Box } from '@mui/material';

// project import
import Header from './Header';

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
  return (
    <Box sx={{ display: 'flex', width: '100%' }}>
      <Header />
      <Outlet />
    </Box>
  );
};

export default MainLayout;
