
import { FETCH_PRODUCTS_SUCCESS, ZERO_OUT_QUANTITY_SUCCESS } from '../../../consts/reduxActions';

const initialProductsState = null;

export const productsReducer = (state = initialProductsState, action) => {
    switch (action.type) {
        case FETCH_PRODUCTS_SUCCESS:
            return action.payload;

        case ZERO_OUT_QUANTITY_SUCCESS:
            if (state && Array.isArray(state)) {
                return state.map(product => {
                    if (product.id === action.payload) {
                        return { ...product, quantity: 0 };
                    }
                    return product;
                });
            }
            return state;

        default:
            return state;
    }
};