import { Box, styled } from '@mui/material';
const HeaderNavigationStyled = styled(Box)(({ theme, isSelected }) => ({
    '& .parentBox': {
        height: '40px',
        marginX: '0.5rem',
        display: 'flex',
        alignItems: 'center',
        // padding: '0.2rem',
        borderRadius: '6px',
        marginTop: '15px',
        marginBottom: '15px'
    },
    '& .styledButton': {
        fontSize: '1rem',
        backgroundColor: isSelected ? theme.palette.primary.main : 'transparent',
        color: isSelected ? 'white' : 'black',
        marginLeft: '5px',
        height: '30px',
        width: 'auto',
        '&:hover': {
            color: 'white',
            backgroundColor: theme.palette.primary.main,
        }
    }
}));

export default HeaderNavigationStyled;
