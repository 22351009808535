import { combineReducers } from 'redux';

// ==============================|| COMBINE REDUCERS ||============================== //

import { productsReducer } from './inventory/products';
import { vendorsReducer } from './inventory/vendors';
import { categoriesReducer } from './inventory/categories';
import { tableFiltersReducer } from './inventory/pagination';
import { orderReducer } from './orders/order';
import { orderProductsReducer } from './orders/orderProducts';
import { productReducer } from './products/product';
import { productCategoriesReducer } from './products/productCategories';
import { productTableFiltersReducer } from './products/productPagination';
import { forgotPasswordReducer } from './user/forgotPassword';
import { usersReducer } from './user/users';
import { usersTableFiltersReducer } from './user/pagination';
import { totalUsersReducer } from './user/totalUsers';
import { totalProductsReducer } from './inventory/totalProducts';
import { totalProductReducer } from './products/totalProduct';

// Inventory reducers
const inventoryReducer = combineReducers({
    products: productsReducer,
    vendors: vendorsReducer,
    categories: categoriesReducer,
    filters: tableFiltersReducer,
    totalProducts: totalProductsReducer
});

// Orders reducers
const ordersReducer = combineReducers({
    orders: orderReducer,
    orderProducts: orderProductsReducer
});

const productsPageReducer = combineReducers({
    products: productReducer,
    categories: productCategoriesReducer,
    filters: productTableFiltersReducer,
    totalProducts: totalProductReducer
})

const userReducer = combineReducers({
    usersList: usersReducer,
    forgotPassword: forgotPasswordReducer,
    filters: usersTableFiltersReducer,
    totalUsers: totalUsersReducer
})

// Root reducer
const rootReducer = combineReducers({
    inventory: inventoryReducer,
    orders: ordersReducer,
    products: productsPageReducer,
    user: userReducer
});


export default rootReducer;