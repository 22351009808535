const Typography = (fontFamily = 'Livvic') => ({
  htmlFontSize: 16,
  fontFamily,
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightBold: 600,
  h1: {
    fontWeight: 600,
    fontSize: '2.228rem', // Additional 10% increase from 2.025rem
    lineHeight: 1.21
  },
  h2: {
    fontWeight: 600,
    fontSize: '1.782rem', // Additional 10% increase from 1.62rem
    lineHeight: 1.27
  },
  h3: {
    fontWeight: 600,
    fontSize: '1.337rem', // Additional 10% increase from 1.215rem
    lineHeight: 1.33
  },
  h4: {
    fontWeight: 600,
    fontSize: '1.188rem', // Additional 10% increase from 1.08rem
    lineHeight: 1.4
  },
  h5: {
    fontWeight: 600,
    fontSize: '0.891rem', // Additional 10% increase from 0.81rem
    lineHeight: 1.5
  },
  h6: {
    fontWeight: 400,
    fontSize: '0.743rem', // Additional 10% increase from 0.675rem
    lineHeight: 1.57
  },
  caption: {
    fontWeight: 400,
    fontSize: '0.713rem', // Additional 10% increase from 0.648rem
    lineHeight: 1.66
  },
  body1: {
    fontSize: '0.832rem', // Additional 10% increase from 0.756rem
    lineHeight: 1.57
  },
  body2: {
    fontSize: '0.713rem', // Additional 10% increase from 0.648rem
    lineHeight: 1.66
  },
  subtitle1: {
    fontSize: '0.832rem', // Additional 10% increase from 0.756rem
    fontWeight: 600,
    lineHeight: 1.57
  },
  subtitle2: {
    fontSize: '0.713rem', // Additional 10% increase from 0.648rem
    fontWeight: 500,
    lineHeight: 1.66
  },
  overline: {
    lineHeight: 1.66
  },
  button: {
    textTransform: 'capitalize'
  }
});

export default Typography;
