// vendorsReducer.js

import { FETCH_VENDORS_SUCCESS } from '../../../consts/reduxActions';

const initialVendorsState = null;

export const vendorsReducer = (state = initialVendorsState, action) => {
    switch (action.type) {
        case FETCH_VENDORS_SUCCESS:
            return action.payload
        default:
            return state;
    }
};
