import { FETCH_ORDER_PRODUCTS_SUCCESS } from "consts/reduxActions";
// Define initial state
const initialState = null;

// Define the order reducer
export const orderProductsReducer = (state = initialState, action) => {
  switch (action.type) {
    // Add cases for different actions
    case FETCH_ORDER_PRODUCTS_SUCCESS:
       return action.payload;
    default:
      return state;
  }
};