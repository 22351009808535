import { GET_PRODUCTS_INIT, GET_PRODUCTS_SUCCESS, GET_PRODUCTS_ERROR } from "consts/reduxActions";

// Define the products reducer
export const productReducer = (state = [], action) => {
    switch (action.type) {
        // Add cases for different actions
        case GET_PRODUCTS_INIT:
            return [];
        case GET_PRODUCTS_SUCCESS:
            return action.payload;
        case GET_PRODUCTS_ERROR:
            return action.payload;
        default:
            return state;
    }
};