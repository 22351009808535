import { GET_ALL_USERS_INIT, GET_ALL_USERS_SUCCESS, GET_ALL_USERS_ERROR, GET_ALL_USERS_UPDATE } from "consts/reduxActions";

// Define the products reducer
export const usersReducer = (state = [], action) => {
    switch (action.type) {
        // Add cases for different actions
        case GET_ALL_USERS_INIT:
            return [];
        case GET_ALL_USERS_SUCCESS:
            return action.payload;
        case GET_ALL_USERS_ERROR:
            return action.payload;
        case GET_ALL_USERS_UPDATE:
            return action.payload;
        default:
            return state;
    }
};