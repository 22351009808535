import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

// third-party
import StoreProvider from 'store/storeProvider';

// project import
import App from './App';

// ==============================|| MAIN - REACT DOM RENDER  ||============================== //

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <StrictMode>
    <StoreProvider>
      <BrowserRouter basename="/">
        <App />
      </BrowserRouter>
    </StoreProvider>
  </StrictMode>
);
