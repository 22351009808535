import { login, forgotPasswordRequest, createNewPassword } from 'services/auth';

const loginUser = (payload) => (dispatch) => {
  return login(payload)
    .then((data) => {
      if (data && data.user) {
        const { roleId, token } = data.user;
        // Set role and token in local storage based on roleId
        if (roleId === 1) {
          localStorage.setItem('role', 'admin');
        } else if (roleId === 2) {
          localStorage.setItem('role', 'employee');
        }
        localStorage.setItem('token', token);
      }
      return Promise.resolve(data);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

const logoutUser = () => (dispatch) => {
  localStorage.removeItem('role'); // Clear role from local storage
  localStorage.removeItem('token');

  return Promise.resolve();
};

const forgotPasswordLink = (email) => (dispatch) => {
  return forgotPasswordRequest(email)
    .then((data) => {
      console.log(data);
      return Promise.resolve(data);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

const createPassword = (password, token, isNewUser) => (dispatch) => {
  return createNewPassword(password, token, isNewUser)
    .then((data) => {
      console.log(data);
      return Promise.resolve(data);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export { loginUser, logoutUser, forgotPasswordLink, createPassword };
