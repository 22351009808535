import { UPDATE_PRODUCT_PAGINATION_TOTAL } from '../../../consts/reduxActions';

const initialTotalProductsState = 10

export const totalProductReducer = (state = initialTotalProductsState, action) => {
    switch (action.type) {
        case UPDATE_PRODUCT_PAGINATION_TOTAL:
            return action.payload;
        default:
            return state;
    }
};
