import { createStore, applyMiddleware, compose } from 'redux';
import rootReducer from './reducers/index';
import logger from 'redux-logger';
import thunk from 'redux-thunk'; // Import redux-thunk

const configureStore = () => {
    const middlewares = [thunk]; // Add thunk to middleware

    if (process.env.NODE_ENV === 'development') {
        middlewares.push(logger);
    }

    const store = createStore(
        rootReducer,
        compose(
            applyMiddleware(...middlewares),
            window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f
        )
    );

    return store;
};

export default configureStore;