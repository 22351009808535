import { UPDATE_INVENTORY_PAGINATION_TOTAL } from '../../../consts/reduxActions';

const initialTotalProductsState = 10

export const totalProductsReducer = (state = initialTotalProductsState, action) => {
  switch (action.type) {
    case UPDATE_INVENTORY_PAGINATION_TOTAL:
      return action.payload;
    default:
      return state;
  }
};
