import { FETCH_ORDERS_SUCCESS, UPDATE_ORDERS_SUCCESS } from "consts/reduxActions";
// Define initial state
const initialState = null;

// Define the order reducer
export const orderReducer = (state = initialState, action) => {
  switch (action.type) {
    // Add cases for different actions
    case FETCH_ORDERS_SUCCESS:
       return action.payload ;
    case UPDATE_ORDERS_SUCCESS:
       return action.payload ;
    default:
      return state;
  }
};