import axios from 'axios';

// Base url
const BASE_URL = process.env.REACT_APP_BASE_URL;

const forgotPasswordRequest = async (email) => {
  const payload = { email: email };

  return axios
    .post(`${BASE_URL}/users/forgotPassword`, payload)
    .then((response) => {
      return Promise.resolve(response.data.status);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

const login = async (payload) => {
  return axios
    .post(`${BASE_URL}/users/login`, payload)
    .then((response) => {
      return Promise.resolve(response.data.data);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

const createNewPassword = async (password, passwordToken, isNewUser) => {
  if (isNewUser) {
    const payload = { password: password };

    return axios
      .post(`${BASE_URL}/users/createUserFromInvitation?token=${passwordToken}`, payload)
      .then((response) => {
        return Promise.resolve(response.data.status);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  } else {
    const payload = { newPassword: password, token: passwordToken };

    return axios
      .post(`${BASE_URL}/users/resetPassword`, payload)
      .then((response) => {
        return Promise.resolve(response.data.status);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
};

export { login, forgotPasswordRequest, createNewPassword };
