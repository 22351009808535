import { GET_PRODUCT_CATEGORIES_INIT, GET_PRODUCT_CATEGORIES_SUCCESS, GET_PRODUCT_CATEGORIES_ERROR } from "consts/reduxActions";

// Define the order reducer
export const productCategoriesReducer = (state = [], action) => {
    switch (action.type) {
        // Add cases for different actions
        case GET_PRODUCT_CATEGORIES_INIT:
            return [];
        case GET_PRODUCT_CATEGORIES_SUCCESS:
            return action.payload;
        case GET_PRODUCT_CATEGORIES_ERROR:
            return action.payload;
        default:
            return state;
    }
};