// categoriesReducer.js

import { FETCH_CATEGORIES_SUCCESS } from '../../../consts/reduxActions';

const initialCategoriesState = null;

export const categoriesReducer = (state = initialCategoriesState, action) => {
    switch (action.type) {
        case FETCH_CATEGORIES_SUCCESS:
            return action.payload
        default:
            return state;
    }
};
