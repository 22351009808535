import { UPDATE_USERS_PAGINATION_TOTAL } from '../../../consts/reduxActions';

const initialTotalUsersState = 10

export const totalUsersReducer = (state = initialTotalUsersState, action) => {
    switch (action.type) {
        case UPDATE_USERS_PAGINATION_TOTAL:
            return action.payload;
        default:
            return state;
    }
};
