import { UPDATE_USERS_PAGINATION, USERS_PAGINATION_RESET } from '../../../consts/reduxActions';

const initialTableFiltersState = {
    pagination: {
        current: 1,
        pageSize: 10,
        pageSizeOptions: ['10', '20', '30', '50'],
        showSizeChanger: true,
        position: ['bottomRight'],
        sorter: {},
        total: 10
    },
    sorter: {},
    role: [],
    status: [],
    search: ''
};

export const usersTableFiltersReducer = (state = initialTableFiltersState, action) => {
    switch (action.type) {
        case UPDATE_USERS_PAGINATION:
            return {
                ...state,
                ...action.payload
            };
        case USERS_PAGINATION_RESET:
            return {
                ...initialTableFiltersState
            };
        default:
            return state;
    }
};