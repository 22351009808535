import { UPDATE_FORGOT_PASSWORD_EMAIL_ADDRESS } from "consts/reduxActions";

// Define the products reducer
export const forgotPasswordReducer = (state = null, action) => {
    switch (action.type) {
        // Add cases for different actions
        case UPDATE_FORGOT_PASSWORD_EMAIL_ADDRESS:
            return action.payload;
        default:
            return state;
    }
};