// project import
import Routes from 'routes';
import ThemeCustomization from 'themes';
import CustomizedScroll from 'themes/overrides/Scroll';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

// css
import './App.css'
import mixpanel from 'mixpanel-browser/src/loaders/loader-module-core';

// ==============================|| APP - THEME, ROUTER, LOCAL  ||============================== //

mixpanel.init('d4209382eab71b4b6adcf19ff21d82ab', {
  debug: true,
  track_pageview: true,
  persistence: 'localStorage',
  cross_subdomain_cookie: false
});

const App = () => (
  <DndProvider backend={HTML5Backend}>
    <ThemeCustomization>
      <CustomizedScroll>
        <Routes />
        <ToastContainer position={"top-right"} autoClose={3000} />
      </CustomizedScroll>
    </ThemeCustomization>
  </DndProvider>
);

export default App;
