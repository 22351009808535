import { styled } from '@mui/system';

const CustomizedScroll = styled('div')(({ theme }) => ({
  '& *': {
    scrollbarWidth: 'thin', // For Firefox
    scrollbarColor: `${theme.palette.primary.light} ${theme.palette.primary.lighter}`, // For Firefox
  },
  '& *::-webkit-scrollbar': {
    width: '8px', // Width of the scrollbar
    height: '8px'
  },
  '& *::-webkit-scrollbar-track': {
    background: '#ffff', // Color of the track
    borderRadius: '4px', // Roundness of the track
  },
  '& *::-webkit-scrollbar-thumb': {
    backgroundColor: theme.palette.primary[100], // Color of the scroll thumb
    borderRadius: '4px', // Roundness of the thumb
  },
  '& *::-webkit-scrollbar-thumb:hover': {
    backgroundColor: theme.palette.primary.lighter, // Color of the scroll thumb when hovered
  },
}));

export default CustomizedScroll;

