// actionTypes.js

export const FETCH_PRODUCTS_START = 'FETCH_PRODUCTS_START';
export const FETCH_PRODUCTS_SUCCESS = 'FETCH_PRODUCTS_SUCCESS';
export const FETCH_PRODUCTS_ERROR = 'FETCH_PRODUCTS_ERROR';

export const ZERO_OUT_QUANTITY_START = 'ZERO_OUT_QUANTITY_START';
export const ZERO_OUT_QUANTITY_SUCCESS = 'ZERO_OUT_QUANTITY_SUCCESS';
export const ZERO_OUT_QUANTITY_ERROR = 'ZERO_OUT_QUANTITY_ERROR';

export const UPLOAD_CSV_START = 'UPLOAD_CSV_START';
export const UPLOAD_CSV_SUCCESS = 'UPLOAD_CSV_SUCCESS';
export const UPLOAD_CSV_ERROR = 'UPLOAD_CSV_ERROR';

export const FETCH_VENDORS_START = 'FETCH_VENDORS_START';
export const FETCH_VENDORS_SUCCESS = 'FETCH_VENDORS_SUCCESS';
export const FETCH_VENDORS_ERROR = 'FETCH_VENDORS_ERROR';

export const FETCH_CATEGORIES_START = 'FETCH_CATEGORIES_START';
export const FETCH_CATEGORIES_SUCCESS = 'FETCH_CATEGORIES_SUCCESS';
export const FETCH_CATEGORIES_ERROR = 'FETCH_CATEGORIES_ERROR';

export const UPDATE_TABLE_FILTERS_SUCCESS = 'UPDATE_TABLE_FILTERS_SUCCESS';
export const UPDATE_INVENTORY_PAGINATION_TOTAL = 'UPDATE_INVENTORY_PAGINATION_TOTAL';
export const UPDATE_INVENTORY_PAGINATION = 'UPDATE_INVENTORY_PAGINATION';
export const INVENTORY_PAGINATION_RESET = 'INVENTORY_PAGINATION_RESET';

export const UPDATE_PRODUCT_TABLE_FILTERS_SUCCESS = 'UPDATE_PRODUCT_TABLE_FILTERS_SUCCESS';
export const UPDATE_PRODUCT_PAGINATION_TOTAL = 'UPDATE_PRODUCT_PAGINATION_TOTAL';
export const UPDATE_PRODUCT_PAGINATION = 'UPDATE_PRODUCT_PAGINATION';
export const PRODUCTS_PAGINATION_RESET = 'PRODUCTS_PAGINATION_RESET';

export const UPDATE_USERS_TABLE_FILTERS_SUCCESS = 'UPDATE_USERS_TABLE_FILTERS_SUCCESS';
export const UPDATE_USERS_PAGINATION_TOTAL = 'UPDATE_USERS_PAGINATION_TOTAL';
export const UPDATE_USERS_PAGINATION = 'UPDATE_USERS_PAGINATION';
export const USERS_PAGINATION_RESET = 'USERS_PAGINATION_RESET';

export const FETCH_ORDERS_SUCCESS = 'FETCH_ORDERS_SUCCESS';
export const UPDATE_ORDERS_SUCCESS = 'UPDATE_ORDERS_SUCCESS';
export const FETCH_ORDER_PRODUCTS_SUCCESS = 'FETCH_ORDER_PRODUCTS_SUCCESS';

export const GET_PRODUCTS_INIT = 'GET_PRODUCTS_INIT';
export const GET_PRODUCTS_SUCCESS = 'GET_PRODUCTS_SUCCESS';
export const GET_PRODUCTS_ERROR = 'GET_PRODUCTS_ERROR';

export const GET_PRODUCT_CATEGORIES_INIT = 'GET_PRODUCT_CATEGORIES_INIT';
export const GET_PRODUCT_CATEGORIES_SUCCESS = 'GET_PRODUCT_CATEGORIES_SUCCESS';
export const GET_PRODUCT_CATEGORIES_ERROR = 'GET_PRODUCT_CATEGORIES_ERROR';

export const UPDATE_FORGOT_PASSWORD_EMAIL_ADDRESS = 'UPDATE_FORGOT_PASSWORD_EMAIL_ADDRESS';

export const GET_ALL_USERS_INIT = 'GET_ALL_USERS_INIT';
export const GET_ALL_USERS_SUCCESS = 'GET_ALL_USERS_SUCCESS';
export const GET_ALL_USERS_ERROR = 'GET_ALL_USERS_ERROR';
export const GET_ALL_USERS_UPDATE = 'GET_ALL_USERS_UPDATE';