// Import necessary dependencies
import { useRoutes, Navigate } from 'react-router-dom';
import { lazy } from 'react';

// Import your components and layouts
import Loadable from 'components/Loadable';
import MainLayout from 'layout/MainLayout';

// Lazy load your pages
const DashboardDefault = Loadable(lazy(() => import('pages/dashboard/Orders')));
const Inventory = Loadable(lazy(() => import('pages/dashboard/Inventory')));
const Products = Loadable(lazy(() => import('pages/dashboard/Products')));
const Users = Loadable(lazy(() => import('pages/dashboard/Users')));
const Login = Loadable(lazy(() => import('pages/auth/Login')));
const ForgotPassword = Loadable(lazy(() => import('pages/auth/ForgotPassword')));
const VerficationLinkSuccess = Loadable(lazy(() => import('pages/auth/ForgotPassword/VerficationLinkSuccess')));
const CreateNewPassword = Loadable(lazy(() => import('pages/auth/ForgotPassword/CreateNewPassword')));

// Protected Route Component
const ProtectedRoute = ({ children, allowedRoles }) => {
  const token = localStorage.getItem('token');
  const role = localStorage.getItem('role');

  if (!token) {
    // User is not logged in, redirect to login
    return <Navigate to="/" />;
  }

  if (allowedRoles && !allowedRoles.includes(role)) {
    // User does not have the right role, redirect to dashboard or another appropriate page
    return <Navigate to="/" />;
  }

  return children;
};

// Login Route
const LoginRoute = {
  path: '/',
  element: <Login />
};

const ForgotPasswordRoute = {
  path: '/forgotpassword',
  element: <ForgotPassword />
};

const VerficationLinkSuccessRoute = {
  path: '/verificationlinksuccess',
  element: <VerficationLinkSuccess />
};

const CreateNewPasswordRoute = {
  path: '/users/createUserFromInvitation/:token?',
  element: <CreateNewPassword isNewUser={false} />
};

const CreateNewUserPassowordRoute = {
  path: '/verification/:token?',
  element: <CreateNewPassword isNewUser={true} />
};

// Main Routes
const MainRoutes = {
  path: '/',
  element: <MainLayout />,
  children: [
    {
      path: 'orders',
      element: (
        <ProtectedRoute allowedRoles={['employee']}>
          <DashboardDefault />
        </ProtectedRoute>
      )
    },
    {
      path: 'inventory',
      element: (
        <ProtectedRoute allowedRoles={['employee']}>
          <Inventory />
        </ProtectedRoute>
      )
    },
    {
      path: 'users',
      element: (
        <ProtectedRoute allowedRoles={['admin', 'employee']}>
          <Users />
        </ProtectedRoute>
      )
    },
    {
      path: 'products',
      element: (
        <ProtectedRoute allowedRoles={['admin', 'employee']}>
          <Products />
        </ProtectedRoute>
      )
    }
  ]
};

// Routing Render Function
export default function ThemeRoutes() {
  return useRoutes([
    LoginRoute,
    ForgotPasswordRoute,
    VerficationLinkSuccessRoute,
    CreateNewPasswordRoute,
    CreateNewUserPassowordRoute,
    MainRoutes
  ]);
}
