// Redux
import { useDispatch } from 'react-redux';

// Routing
import { useNavigate } from 'react-router-dom';

// Mui
import AssessmentIcon from '@mui/icons-material/Assessment';
import GroupIcon from '@mui/icons-material/Group';
import InventoryIcon from '@mui/icons-material/Inventory';
import ViewListIcon from '@mui/icons-material/ViewList';
import { Box, Typography } from '@mui/material';
import NavigationButton from './NavigationButton/navigationButton';

// ==============================|| HEADER - CONTENT ||============================== //
import LogoutIcon from '@mui/icons-material/Logout';
import logo2_v2 from '../../../../assets/images/icons/logo2_v2.svg';

// Actions
import { logoutUser } from 'actions/auth';
import mixpanel from 'mixpanel-browser/src/loaders/loader-module-core';

const HeaderContent = () => {
  // Redux
  const dispatch = useDispatch();

  // Navigation
  const navigate = useNavigate();

  // Get the user role from localStorage
  const role = localStorage.getItem('role');

  // Logout functionality
  const handleLogout = () => {
    dispatch(logoutUser()).then(() => {
      mixpanel.stop_session_recording();
      mixpanel.reset();
      navigate('/');
    });
  };

  return (
    <>
      <Box sx={{ display: 'flex', flexGrow: 0 }} gap={0}>
        <img src={logo2_v2} alt="PowerLeaf" />
      </Box>

      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexGrow: 1 }}>
        <Box sx={{ display: 'flex', justifyContent: 'center', flexGrow: 1 }} gap={8}>
          {role === 'employee' && (
            <NavigationButton label="Orders" routePath="/orders" icon={<ViewListIcon color="primary" fontSize="medium" />} />
          )}

          {/* Show Inventory button for employee only */}
          {role === 'employee' && (
            <NavigationButton label="Inventory" routePath="/inventory" icon={<InventoryIcon color="primary" fontSize="medium" />} />
          )}

          {/* Show Manage Users button for both admin and employee roles */}
          {(role === 'admin' || role === 'employee') && (
            <NavigationButton label="Manage Users" routePath="/users" icon={<GroupIcon color="primary" fontSize="medium" />} />
          )}

          {/* Show Products button only for admin role */}
          {role === 'admin' && (
            <NavigationButton label="Products" routePath="/products" icon={<AssessmentIcon color="primary" fontSize="medium" />} />
          )}
        </Box>
        <Typography
          onClick={handleLogout}
          color="primary"
          sx={{
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            marginRight: 2,
            textDecoration: 'underline'
          }}
        >
          <LogoutIcon color="primary" sx={{ mr: 0.5 }} /> Logout
        </Typography>
      </Box>
    </>
  );
};

export default HeaderContent;
